<!-- 中心介绍 -->

<template>
  <div class="box">
    <div class="nav_top">
      <div class="top">
        <top-nav></top-nav>
      </div>
      <div class="text">
        <div class="first">绿水青山就是金山银山</div>
      </div>
    </div>
    <div style="background-color: #f5f5f5;">
      <div class="content_c">
        <el-tabs :tab-position="tabPosition" v-model="defaultName" @tab-click="handleClick">
          <el-tab-pane :disabled="true">
            <span slot="label"><img style="
    width: 30px;
    height: 6px;" src="../assets/center/left.png" alt=""> 联系我们 <img style="
    width: 30px;
    height: 6px;" src="../assets/center/right.png" alt=""></span>
          </el-tab-pane>

          <el-tab-pane :label="item.typename" v-for="(item, index) in leftList" :key="index">
            <div class="crumbs">
              <div class="crumbs_img"><img src="../assets/home.png" alt=""></div>
              <div class="crumbs_text">
                您当前得位置:</div>
              <div class="crumbs_content">
                <span>首页 ></span>
                <span>联系我们 ></span>
                <span>{{ item.typename }}</span>
              </div>
            </div>
            <!-- 联系我们 -->
            <div class="call" v-if="active == 0">
              <div class="code">
                <div class="title">
                  <h1>长三角（义乌）生态环境研究中心</h1>
                  <div>
                    <span>地址：浙江省金华市义乌市北苑街道雪峰西路968号 </span>
                    <span> 电话：0579-83815373</span>
                    <span> e-mail：RCEES_ywcsjzx@163.com</span>
                  </div>
                </div>
                <div class="wx-code">
                  <div>
                    <img src="../assets/public.jpg" alt="" />
                  </div>
                  <div>微信公众号</div>
                </div>
              </div>
              <div style="
    padding: 20px 0;
">
                <!--百度地图容器-->

                <baidu-map class="map" :center="{ lng: 120.042487, lat: 29.316552 }" :zoom="15" scroll-wheel-zoom>
                  <bm-marker :position="{ lng: 120.042487, lat: 29.316552 }" :dragging="true"
                    animation="BMAP_ANIMATION_BOUNCE" @click="infoWindowOpen">
                    <!-- <bm-info-window
                    width="0"
                    height="0"
                    :show="show"
                    @close="infoWindowClose"
                    @open="infoWindowOpen"
                    >我爱北京天安门</bm-info-window
                  > -->
                    <bm-label content="长三角（义乌）生态环境研究中心" :labelStyle="{
                      color: '#000',
                      fontSize: '24px',
                      border: '1px solid #ccc',
                    }" :offset="{ width: -155, height: 55 }" />

                    <!-- <bm-label
                    content=""
                    :labelStyle="{ color: 'red', fontSize: '18px' }"
                    :offset="{ width: -155, height: 55 }"
                  /> -->
                  </bm-marker>
                </baidu-map>
              </div>
            </div>
            <!-- 在线留言 -->
            <div class="leave" v-if="active == 1">
              <div class="from">
                <div class="cart-small-heading">
                  <div class="font-24">留言反馈</div>
                </div>
                <!-- <form class="needs-validation"> -->
                <el-form class="form" ref="formNew" :model="form" label-width="80px" :rules="rules">
                  <el-form-item label="联系人" prop="title">
                    <el-input v-model="form.title" placeholder="请输入您的名称（称呼）"></el-input>
                  </el-form-item>
                  <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="form.phone" placeholder="请输入您的联系电话"></el-input>
                  </el-form-item>
                  <el-form-item label="联系邮箱" prop="email">
                    <el-input v-model="form.email" placeholder="请输入您的邮箱"></el-input>
                  </el-form-item>
                  <el-form-item label="留言内容" prop="content">
                    <el-input type="textarea" v-model="form.content" placeholder="请输入您详细的需求内容"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSubmit">提交内容</el-button>
                  </el-form-item>
                </el-form>
                <!-- </form> -->
              </div>
            </div>
            <!-- 人才招聘 -->
            <div class="recruitment" v-if="active == 2">
              <div class="blog-detail">
                <div class="text mb-30">
                  <!-- <h6 class="detail-title">{{ detailsItem.title }}</h6> -->
                  <!-- <p class="time">发布时间：{{ detailsItem.createTime }}</p> -->
                  <p class="rich" v-html="detailsItem.contentEdit"></p>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav";
import bottom from "../components/bottom";
import { province } from "../api/api";
import { addMsg, getByTitle } from "../api/api";

export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {
      tabPosition: "left",
      newsList: [],
      leftList: [
        {
          typename: "联系方式",
        },
        {
          typename: "留言反馈",
        },
        {
          typename: "人才招聘",
        },
      ],
      detailsItem: {},
      active: 0,
      defaultName: '1',
      form: {
        title: "",
        phone: "",
        email: '',
        content: "",
      },

      rules: {
        title: [{ required: true, message: "请输入您的名称", trigger: "blur" }],
        phone: [{ required: true, message: "请输入您的手机号", trigger: "blur" }],
        email: [{ required: true, message: "请输入您的邮箱", trigger: "blur" }],
        content: [{ required: true, message: "请输入您的需求内容", trigger: "blur" }],
      },

      center: { lng: 0, lat: 0 },
      zoom: 5,
      show: false,
    };
  },
  beforeMount() {
    let _province = localStorage.getItem("province");
    if (!_province) {
      this.getprovince();
    }
  },
  mounted() {
    this.details();
  },
  methods: {
    //获取省市
    async getprovince() {
      let res = await province();
      if (res.ResultCode == 0) {
        console.log(res, "res");
        localStorage.setItem("province", JSON.stringify(res.Data));
      }
    },
    async details() {
      let res = await getByTitle('人才招聘');
      if (res.ResultCode == 0) {
        // console.log(res.Data, "res详情");
        this.detailsItem = res.Data;
      }
    },
    handleClick(tab) {
      this.active = tab.index -1;
      // console.log(tab.index, this.leftList[tab.index]);
      //   this.getDate(this.leftList[tab.index].pid);
    },
    // 提交留言
    async onSubmit() {
      if (!this.form.title) {

        this.$message.error("请填写您的名称");
        return
      }
      if (!this.form.phone) {

        this.$message.error("请输入您的手机号");
        return
      }
      if (!this.form.email) {

        this.$message.error("请输入您的邮箱");
        return
      }
      if (!this.form.content) {

        this.$message.error("请输入您的需求内容");
        return
      }
      let res = await addMsg(this.form);
      if (res.ResultCode == 0) {
        this.$message.success("提交成功");
        this.form = {
          title: "",
          phone: "",
          email: "",
          content: "",
        };
        console.log(res, "res");
      }

    },
    handler({ BMap, map }) {
      console.log(BMap, map);
      this.center.lng = 116.404;
      this.center.lat = 39.915;
      this.zoom = 20;
    },
    infoWindowClose(e) {
      console.log(e);
      this.show = false;
    },
    infoWindowOpen(e) {
      console.log(e);
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  .el-tab-pane {
  margin-left:  6.25rem;
}
 // 面包屑
 .crumbs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #666666;
    font-size: 14px;
    font-weight: 500;
  
    height: 50px;
    &_img {
      width: 18px;
      height: 18px;
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  
    &_text {
      padding-left: 10px;
    }
  
    &_content {
      color: #666666;
      font-size: 14px;
    }
  }

// 联系我们
.call {
  background-color: #fff;
  .map {
    width: 55.7875rem;//48.7875rem
    height: 30.1875rem;
    text-align: center;
    line-height: 30.1875rem;
    margin: 1.25rem;
    background-color: #eee;
    margin-bottom: 1.25rem;
  }

  .title {
    h1 {
      margin: 1.25rem 0;
      color: #333333;
      font-size: 1.25rem;
      font-weight: bold;
    }

    span {
      padding-right: 1.25rem;
      color: #666666;
      font-size: 1rem;
    }
  }

  .code {
    display: flex;
    justify-content: space-between;
    padding: 0 1.25rem;

    .wx-code {
      text-align: center;

      img {
        width: 5.4375rem;
        height: 5.4375rem;
      }
    }
  }
}

// 在线留言
.leave {
  width: 100%;
  margin: auto;
  overflow: hidden;
  display: flex;
  background-color: #fff;

  .map {
    width: 30.1875rem;
    height: 30.1875rem;
    text-align: center;
    line-height: 30.1875rem;
    margin: 1.25rem;
    background-color: #eee;
  }

  /* 留言 */
  .from {
    margin-left: 3.125rem;
    width: 36.6875rem;

    .cart-small-heading {
      padding-left: 1.25rem;
      height: 3.125rem;
      line-height: 3.125rem;
      margin: 1.875rem 0;
      font-size: .875rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      border-bottom: 1px solid #e5e5e5;
    }

    .form {
      margin-right: 30%;
    }
  }
}
.recruitment{
  background-color: #fff;
}
.rich {
  ::v-deep img {
    width: 100%;
    margin: auto;
  }

  ::v-deep p {
    line-height: 3;
  }
}
.box .content_c p{
    text-indent: 0 !important;
  }
.box {
  width: 100%;
  box-sizing: border-box;

  .nav_top {
    width: 100%;
    height: 50vh;
    background-image: url("../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;

    .text {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      font-size: 3.125rem;
      font-family: 'YanShiYouRanXiaoKai';

      transition: bounce-in 3s;

      .first {
        font-size: 6.875rem;
        // font-weight: bold;
        margin-bottom: 3.125rem;
      }
    }
    .top {
      width: 100%;
      height: 6.25rem;
      background-size: 100% 6.25rem;
      background-repeat: no-repeat;
    }

    h1 {
      font-size: 4.125rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 8.75rem;
    }

    h2 {
      font-size: 2.5rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 3.1875rem;
    }
  }

  .content_c {
    // width: 65%;
    width: 87.5rem;
    min-width: 800px;
    margin: 0 auto;
    padding-top: 4.5rem;
    padding-bottom: 3.125rem;
    // 新闻列表

    .blog-detail {
      width: 100%;
      height: 100%;
      padding: 0 2.3125rem;
      // margin: 1.25rem auto;
      overflow: hidden;
      text-align: left;
    }

    .blog-detail .detail-title {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      margin: 1.25rem auto;
    }

    .blog-detail .text {
      width: 100%;
    }

    .blog-detail .text .time {
      text-align: center;
      margin: 1.25rem auto;
    }

    p.rich {
      margin: auto;
    }

    p {
      text-indent: 1.875rem;
    }
  }

  .content5 {
    width: 100%;
    // height: 19.375rem;
    background: #f5f5f5;
  }
}

::v-deep {
 
  .el-tabs__content {
    // background-color: #fff;
  }

  .el-tabs__nav.is-left {
    width: 200px;
    background-color: #fff;
  }

  .el-tabs__item {
    height: 60px;
    line-height: 60px;
  }

  .el-tabs--left .el-tabs__item.is-left {
    text-align: center;
    font-size: 18px;
    // font-family: AlibabaPuHuiTi-2-55-Regular;

    span {
      font-size: 20px;
    }


  }

  .el-tabs__item:hover {
    background-color: rgba(36, 119, 228, .1);
  }

  .el-tabs__item.is-active {
    background-color: rgba(36, 119, 228, .1);

  }

  #tab-0 {
    background-color: #356CEB;
    color: #fff;

  }

  .el-tabs__active-bar.is-right {}
}

.box .content_c p[data-v-3465a1fc]{
text-indent: 0 !important;
  }

</style>
